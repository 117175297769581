import { ACTION_LOGIN } from "../pages/mainPage/LoginPage/constant/Constant";
const initialState = {
  email: "",
  username: "",
  password: "",
  confirmpassword: "",
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default dataReducer;
