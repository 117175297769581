import { ACTION_PAYMENT } from "../pages/mainPage/Payment/constants/Constant";
const initialState = {
  payment_details: [],
  amount: 0,
  account_no: "",
  account_data: "",
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_PAYMENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default dataReducer;
