import React from "react";
import Button from "@mui/material/Button";
const ButtonReusable = (props) => {
  return (
    <div>
      <Button
        variant={props.variant}
        size={props.size}
        onClick={props.onClick}
        endIcon={props.endIcon}
        fullWidth={props.fullWidth}
        sx={{
          display: props.display,
          justifyContent: props.justifyContent,
          alignItems: props.alignItems,
          fontWeight: props.fontWeight,
          padding: props.padding,
          color: props.color,
          border: props.border,
          borderRadius: props.borderRadius,
          backgroundColor: props.backgroundColor,
          fontFamily: props.fontFamily,
          fontSize: props.fontSize,
          width: props.width,
          textTransform: props.textTransform,
          "&:hover": {
            backgroundColor: props.backgroundColor,
            border: props.border,
          },
          // -webkit-box-shadow: 8px 7px 66px -2px rgba(204,210,230,0.75);
          // -moz-box-shadow: 8px 7px 66px -2px rgba(204,210,230,0.75);
        }}
      >
        {props.buttonName}
      </Button>
    </div>
  );
};

export default ButtonReusable;
