import { ACTION_NAV } from "../components/navigation/constant/Constant";

const initialState = {
  selectedItem: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_NAV:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default dataReducer;
