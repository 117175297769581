import React from "react";
import { ACTION_SIGNUP } from "../pages/mainPage/SignUp/constant/Constant";
const initialState = {
  //personal information fields
  username: "",
  password: "",
  firstName: "",
  midName: "",
  lastName: "",
  phoneNo: "",
  email: "",
  dob: "",
  age: "",
  gender: [{ type: "Male" }, { type: "Female" }],
  selectedGender: "",
  nationality: [{ type: "Filipino" }, { type: "Foreign" }],
  selectedNationality: "",
  civilStatus: [{ type: "Single" }, { type: "Married" }],
  selectedCivilStatus: "",
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_SIGNUP:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default dataReducer;
