import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { handleNavItem } from "../../../pages/mainPage/IndexHomePage/action/IndexHomePageAction";
import { useNavigate } from "react-router-dom";
import { NavBarUtils } from "../../../pages/utils/NavBarUtils";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Swal_Loading from "../../loading/Swal_Loading";
import { handleSelectedData } from "../action/NavigationAction";
const NavigationHook = () => {
  // const [menuOpen, setMenuOpen] = useState(false);
  // const toggleMenu = () => {
  //   setMenuOpen(!menuOpen);
  // };
  // console.log(menuOpen);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    isNavItemSelected: "",
    selectedItem: "",
  });

  const handleSelectedNavItem = (route) => {
    navigate(route);
    dispatch(handleSelectedData({ selectedItem: route }));

    // setState((prev) => ({
    //   ...prev,
    //   selectedItem: route,
    // }));
    // setState((prev) => ({
    //   ...prev,
    //   isNavItemSelected: val,
    // }));
    // dispatch(handleNavItem(val.route, navigate));
  };
  // useEffect(() => {
  //   <Swal_Loading />;
  // }, []);
  //   const selectedNavItem = (route) => {
  //     dispatch(handleNavItem(route));
  //   };
  const onClickLogo = () => {
    navigate("/");
  };
  const handleRoute = (route) => {
    navigate(route);
  };

  return { ...state, handleSelectedNavItem, onClickLogo, handleRoute };
};

export default NavigationHook;
