import { combineReducers } from "redux";
import IndexHomePageReducer from "./IndexHomePageReducer";
import ApplicationPageReducer from "./ApplicationPageReducer";
import SignupReducer from "./SignupReducer";
import LoginReducer from "./LoginReducer";
import PayBillsReducer from "./PayBillsReducer";
import NavigationReducer from "./NavigationReducer";

export default combineReducers({
  IndexHomePageReducer: IndexHomePageReducer,
  ApplicationPageReducer: ApplicationPageReducer,
  SignupReducer: SignupReducer,
  LoginReducer: LoginReducer,
  PayBillsReducer: PayBillsReducer,
  NavigationReducer: NavigationReducer,
});
