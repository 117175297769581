import logo from "./logo.svg";
import "./components/responsive-styles/responsiveStyles.css";

import React, { Suspense, useEffect } from "react";
import {
  HashRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import NavigationBar from "./components/navigation/NavigationBar";
import ReusableNav from "./components/navigation/ReusableNav";

// import "./App.css";

//component lazy imports
const Navigation = React.lazy(() =>
  import("./components/navigation/Navigation")
);
const NavBar = React.lazy(() => import("./components/navigation/NavBar"));
const IndexHomePage = React.lazy(() =>
  import("./pages/mainPage/IndexHomePage/IndexHomePage")
);
const IndexContactUsPage = React.lazy(() =>
  import(
    "./pages/mainPage/ContactUsPage/pageComponent/ContactUsPage/IndexContactUsPage"
  )
);
const ApplicationPage = React.lazy(() =>
  import("./pages/mainPage/ApplicationPage/ApplicationPage")
);
const InternetPackage = React.lazy(() =>
  import("./pages/mainPage/PackagePage/IntenetPackage")
);
const IndexSignUpPage = React.lazy(() =>
  import("./pages/mainPage/SignUp/IndexSignUpPage")
);
const IndexLoginPage = React.lazy(() =>
  import("./pages/mainPage/LoginPage/IndexLoginPage")
);
const IndexProfilePage = React.lazy(() =>
  import("./pages/mainPage/ProfilePage/IndexProfilePage")
);
const PayBills = React.lazy(() => import("./pages/mainPage/Payment/PayBills"));
const BillingInformation = React.lazy(() =>
  import(
    "./pages/mainPage/Payment/pageComponent/billingInformation/BillingInformation"
  )
);
const TestPaybill = React.lazy(() =>
  import("./pages/mainPage/Payment/pageComponent/TestPaybill")
);

const Footer = React.lazy(() => import("./components/footer/Footer"));
const LoadingAnimation = React.lazy(() =>
  import("./components/loading/LoadingAnimation")
);
const AboutPage = React.lazy(() =>
  import("./pages/mainPage/AboutPage/AboutPage")
);
const AreaCoverage = React.lazy(() =>
  import("./pages/mainPage/AreaCoveragePage/AreaCoveragePage")
);

function App() {
  // const location = useLocation();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location.pathname]);
  return (
    <div>
      <HashRouter>
        <Suspense>
          <Routes>
            <Route path="/" element={<NavBar />}>
              <Route path="/" element={<IndexHomePage />} />
              <Route path="/contactus" element={<IndexContactUsPage />} />
              <Route path="/application" element={<ApplicationPage />} />
              <Route path="/package" element={<InternetPackage />} />
              <Route path="/userprofile" element={<IndexProfilePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/areacoverage" element={<AreaCoverage />} />

              {/* <Route path="/loading" element={<LoadingAnimation />} /> */}
            </Route>
            <Route path="/signup" element={<IndexSignUpPage />} />
            <Route path="/login" element={<IndexLoginPage />} />
            <Route path="/paybills" element={<PayBills />} />
            <Route path="/billing-info" element={<BillingInformation />} />
            <Route path="/test_paybills" element={<TestPaybill />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </div>
  );
}

export default App;
