export const navItems = [
  {
    item: "Home",
    pages: [
      {
        pageName: "Home",
        route: "/",
      },
    ],
  },
  {
    item: "Package",
    pages: [
      {
        pageName: "Package",
        route: "/packages",
      },
    ],
  },
  {
    item: "Contact Us",
    pages: [
      {
        pageName: "Area Coverage",
        route: "/area_coverage",
      },
    ],
  },
];
