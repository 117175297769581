export const menulist = [
  {
    menu: "Home",
    route: "/",
  },
  // {
  //   menu: "About",
  //   route: "/about",
  // },

  {
    menu: "Packages",
    route: "/package",
  },
  {
    menu: "Area Coverage",
    route: "/areacoverage",
  },
  {
    menu: "Apply now",
    route: "/application",
  },
];
